import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'

// Define the async thunk
export const fetchHomers = createAsyncThunk('games/fetchHomers', async () => {
  const response = await fetch('https://www.phillieshomeruns.com/api/v1/games/2025/getAllHomers');
  const data = await response.json();
  return {
    homers: data.Games,
    status: 'succeeded',
  };
});

// Define the slice
export const homersSlice = createSlice({
  name: 'homers',
  initialState: {
    homers: [],
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHomers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.homers = action.payload.homers;
        console.log('action', action, state);
      })
      .addCase(fetchHomers.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default homersSlice.reducer


