import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'


function ordinal_suffix_of(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}


const PlayerStats = ({player}) => {
  var games = useSelector(state => state.games).games;  
  var homers = useSelector(state => state.homers).homers

  var gamesANDhomers = Enumerable.from(games)
    .join(homers, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right})).toArray();            

                  
  //where gamenumber is less then current game
  var homer = Enumerable.from(gamesANDhomers)
                  .where(c=> c.playjson[0].batter.id ==player.id)
                    .orderByDescending(c=> parseInt(c.gamenumber))                    
                    .firstOrDefault();
  
  var playerOnlyHomers = Enumerable.from(homers).join(games, 
          pk=> pk.gameid,
          fk=> fk.gameid,
          (left, right) => ({...left, ...right}))
            .where(c=> c.playjson[0].batter.id ==player.id)
              .orderByDescending(c=> parseInt(c.gamenumber)) .toArray();
           

          var longestHomerGameHitStreak = 1;
          var currentlongestHomerGameHitStreak = 1;
          var lastgame = 0;
          playerOnlyHomers.map((homerHit, i) => {  
            
            if(parseInt(homerHit.gamenumber) == lastgame -1)
              currentlongestHomerGameHitStreak +=1;
            else
            {
              if (currentlongestHomerGameHitStreak > longestHomerGameHitStreak)
                longestHomerGameHitStreak  = currentlongestHomerGameHitStreak;

                currentlongestHomerGameHitStreak=1;
            }

            lastgame=parseInt(homerHit.gamenumber)

          });

      
    return (
    <div className={"timeline-item"}>
            <div className={"timeline-player-name"}><span title={player.batter}>{player.batter.split(' ')[1]}</span></div>
            <a href={"/player" + homer.playjson[0].batter.id} className={"timeline-player-link"}>                
                <img alt={"avatar"} src={"/images/players/" + homer.playjson[0].batter.id + ".png"} />
            </a>
            <a href={"/player" + homer.playjson[0].batter.id} className={"timeline-item-link"}>                
          <div className={"timeline-item-content"}>            
              <h2>{player.batter} has {(player.total)} homers! </h2>
              <h3>Last homerrun was {homer.playjson[0].gameinfo.date} in the {homer.playjson[0].gameinfo.inning.displayValue}</h3>
              <div className={"timeline-item-content-inside"}>
                <div className={"timeline-item-content-left"}>                                       
                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Home Run Stats</header>
                        <table className={"performance-facts__table--small small-info homerunwidth"}>                                      
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Home Runs</td>             
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>Last 3 games</th>
                            <td></td>
                            <td>{homer.playerstats[0].recenthomeruns.last3games}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 5 games</th>
                            <td></td>
                            <td>{homer.playerstats[0].recenthomeruns.last5games}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 10 games</th>
                            <td></td>
                            <td>{homer.playerstats[0].recenthomeruns.last10games}</td>
                            <td></td>
                          </tr>
                          
                        </tbody>
                      </table>
                        <table className={"performance-facts__table--small small-info homerunwidth"}>
                        <thead>     
                          <tr>
                            <td colSpan={"4"}>As of this game totals</td>        
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>Solo HR</th>
                            <td></td>
                            <td>{homer.playerstats[0].homeruntotalstonow.solo}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>2 run HR</th>
                            <td></td>
                            <td>{homer.playerstats[0].homeruntotalstonow["2run"]}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>3 run HR</th>
                            <td></td>
                            <td>{homer.playerstats[0].homeruntotalstonow["3run"]}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Grandslam</th>
                            <td></td>
                            <td>{homer.playerstats[0].homeruntotalstonow.grandslam}</td>
                            <td></td>
                          </tr>
                            <tr>
                            <th colSpan={"2"}>TOTAL</th>
                            <td></td>
                            <td>{homer.playerstats[0].homeruntotalstonow.total}</td>
                            <td></td>
                          </tr>
                          
                        </tbody>
                      </table>                      
                  </section>
                </div>
                <div className={"timeline-item-content-right"}>                                       
                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>More Home Run Stats!</header>
                        <table className={"performance-facts__table--small small-info homerunwidth"}>
                                      
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Home Runs</td>             
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>Off RHP</th>
                            <td></td>
                            <td>{player.totalRHP}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Off LHP</th>
                            <td></td>
                            <td>{player.totalLHP}</td>
                            <td></td>
                          </tr>     
                          <tr>
                            <th colSpan={"2"}>Off Starters</th>
                            <td></td>
                            <td>{player.totalStarters}</td>
                            <td></td>
                          </tr>    
                          <tr>
                            <th colSpan={"2"}>Off Relief</th>
                            <td></td>
                            <td>{player.totalRelief}</td>
                            <td></td>
                          </tr>   
                          <tr>
                            <th colSpan={"2"}>Go ahead HRs</th>
                            <td></td>
                            <td>{player.totalGoAhead}</td>
                            <td></td>
                          </tr>  
                          <tr>
                            <th colSpan={"2"}>Tieing HRs</th>
                            <td></td>
                            <td>{player.totalTieing}</td>
                            <td></td>
                          </tr>      
                          <tr>
                            <th colSpan={"2"}>Longest HR</th>
                            <td></td>
                            <td> <a href={"/player" + homer.playjson[0].batter.id + "?sort=distance"}>{player.longestHR}</a></td>
                            <td></td>
                          </tr>  
                          <tr>
                            <th colSpan={"2"}>Longest HR hit streak</th>
                            <td></td>
                            <td>{longestHomerGameHitStreak}</td>
                            <td></td>
                          </tr>  
                        </tbody>
                      </table>                                            
                  </section>
                </div>
            </div>
          </div>
               </a>             
      </div>   
)
}
export default PlayerStats
