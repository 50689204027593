import React from 'react'
import { useSelector } from 'react-redux';
import Enumerable from '../app/enumerable';
import GameDay from './GameDay';

const GameList = ({playerId, gameid, recentgames, showHomers}) => {
    const fetchStatus = useSelector(state => state.games.status);
    const homers = useSelector(state => state.homers.homers);
    const games = useSelector(state => state.games.games);

    const sortByDistance   =window.location.search.includes("distance");    
  
    if (fetchStatus === "loading") {
      return <div>LOADING GAME DATA</div>;
    }
  
    if (!games?.length) {
      return <div>No games available</div>;
    }

     // Join games with homers by gameid
    const gamesWithHomers = Enumerable.from(games)
    .join(
      homers,
      (game) => game.gameid,
      (homer) => homer.gameid,
      (game, homer) => ({ ...game, ...homer })
    )
    .toArray();

    // Filter and sort games
  let gamesToDisplay = sortByDistance
      ? Enumerable.from(gamesWithHomers)
      .where(c=> (c.playjson[0].batter.id == playerId || playerId == undefined) && (gameid == undefined || c.gamenumber == gameid))
      .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
      .toArray()
      : Enumerable.from(games)
      .where((c) => !gameid || c.gamenumber === gameid)
      .orderByDescending((c) => parseInt(c.gamenumber, 10))
      .take(recentgames ?? 10)
      .toArray();
      
   return (    
    <div>
         {gamesToDisplay.map((game) => (
            <GameDay
            key={`${game.gameid}-${game.playid}`}
            gamesANDhomers={gamesWithHomers}
            showHomers={showHomers}
            gameinfo={game}
            gameid={game.gameid}
            gamenumber={game.gamenumber}
            gameday={game.date}
            playerId={playerId}
            homer={sortByDistance ? game : null}
            sort={sortByDistance ? "distance" : ""}
            />
          ))}
          </div>
        );
      };

export default GameList

