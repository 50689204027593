import React from 'react'
import { useSelector, useDispatch} from 'react-redux';
 
import TopSocialLinks from './TopSocialLinks';
import TopHeaderMenu from "./TopHeaderMenu";
import SideBarPlayers from "./SideBarPlayers";
import GameList from "./GameList";
import { useParams   } from "react-router-dom";
import { HideShowNoHomers } from '../reducers/gamesReducer';

const Recent = props => { 
    const routeParams = useParams();
    const showHomers = useSelector(state => state.games.shownohomers);
    const dispatch = useDispatch();
 
    return (        
        <div id={"root"}>
              <TopSocialLinks />
              <TopHeaderMenu />
              <div id={"main"}>			
                  <div id={"home"} className={"group"}>
                      <div id={"timeline"} className={"col span_2_of_3"}>    
                      <div className={"timeline-header"}><span>Viewing {routeParams.recentgames >= 162 ? "all games" : routeParams.recentgames + " most recent games"}</span></div>                              
                      <div className={"timeline-header-sub2"}><span id={"hideshow"}><a href={"#"} onClick={() => dispatch(HideShowNoHomers(showHomers ? false : true))}> { showHomers ? 'Hide' : 'Show' } games without a home run</a></span></div>                              
                      <GameList showHomers={showHomers} recentgames={routeParams.recentgames}/>  
                      </div>
                      <div id={"sidebar"} className={"col span_1_of_3"}>
                          <div className={"widget widget_search"}>                            
                          </div>                       
                          <SideBarPlayers />
                      </div>
                  </div>                
              </div>
              <footer id={"footer"} className={"clearfix"}></footer>
      </div>
  );
}

export default Recent

