import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Helper function to calculate day of the year
const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));


export const fetchGames = createAsyncThunk('games/fetchGames', async () => {
  const response = await fetch('https://www.phillieshomeruns.com/api/v1/games/2025/getAllGames');
  const data = await response.json();
  
  return {
    games: data.Games,
    status: 'succeeded',
    hasNewGames : false,
    lastupdated: dayOfYear(new Date()),
  };
});

export const checkForNewGames = createAsyncThunk('games/checkForNewGames', async () => {
  const response = await fetch('https://www.phillieshomeruns.com/api/v1/games/2025/getAllGamesCount');
  const data = await response.json();
  
  return {
    count: data.Games,
  };
});

export const HideShowNoHomers = createAsyncThunk('games/HideShowNoHomers', async (hideshow) => {
  return hideshow;
})

export const gamesSlice = createSlice({
  name: 'games',
  initialState: {
    games: [],
    status:'idle',   
    hasNewGames : "no", 
    lastupdated:0,
    shownohomers: true
  },
  reducers: {   },
  extraReducers(builder) {
    
    builder
      .addCase(fetchGames.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.games = action.payload.games;
        state.lastupdated = action.payload.lastupdated;
        
      })
      .addCase(checkForNewGames.pending, (state, action) => {
        state.hasNewGames  = 'checking'
      })
      .addCase(HideShowNoHomers.fulfilled, (state, action) => {
        
        state.shownohomers = action.payload;
      })

      .addCase(checkForNewGames.fulfilled, (state, action) => {
          // Check if there are new games
          state.hasNewGames  = state.games.length < action.payload.count ? "yes" : "no";
         
      })
     
  }
})

export default gamesSlice.reducer
