import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'
import Team from './team';


function ordinal_suffix_of(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}


const TeamStats = ({}) => {
  
  const { games } = useSelector(state => state.games);
  const { homers  } = useSelector(state => state.homers);
  var gamesANDhomers = Enumerable.from(games)
    .join(homers, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right})).toArray();    

  const countHomersByCondition = (condition) =>{
   return Enumerable.from(gamesANDhomers).where(condition).count();
  }
  
  const getTopPlayerByCondition = (condition) =>
    Enumerable.from(homers)
      .where(condition)
      .groupBy(
        (c) => c.playjson[0].batter.name,
        (element) => element,
        (key, items) => ({ key, count: items.count() })
      )
      .orderByDescending((c) => parseInt(c.count))
      .firstOrDefault();


 var lastGameNumber =  Enumerable.from(games).orderByDescending(c=> parseInt(c.gamenumber)).firstOrDefault();

  var gamesANDhomers = Enumerable.from(games)
    .join(homers, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right})).toArray();            
        
        var teamStats = {};

    
        teamStats.lastHomer = Enumerable.from(gamesANDhomers)
                    .orderByDescending(c=> parseInt(c.gamenumber))
                    .thenByDescending(c=> parseInt(c.playjson[0].gameinfo.inning.number)).firstOrDefault();
    
        teamStats.last3games = countHomersByCondition((c) => parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-3);   
        teamStats.last5games = countHomersByCondition((c) => parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-5);  
        teamStats.last10games = countHomersByCondition((c) => parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-10);  
        teamStats.last20games = countHomersByCondition((c) => parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-20);  
        teamStats.last30games = countHomersByCondition((c) => parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-30);  

        teamStats.total = Enumerable.from(homers).count();

        teamStats.totalRHP = countHomersByCondition((c) => c.playjson[0].pitcher.throws === "RHP");
        teamStats.totalRHPPlayer = getTopPlayerByCondition((c) => c.playjson[0].pitcher.throws === "RHP");

        teamStats.totalLHP = countHomersByCondition((c) => c.playjson[0].pitcher.throws === "LHP");
        teamStats.totalLHPPlayer = getTopPlayerByCondition((c) => c.playjson[0].pitcher.throws === "LHP");
       
        teamStats.totalFullCount= getTopPlayerByCondition((c) => parseInt(c.playjson[0].playinfo.pitchcount.balls) == 3 && parseInt(c.playjson[0].playinfo.pitchcount.strikes)== 2);
        
        teamStats.totalStarters = countHomersByCondition((c) => c.playjson[0].pitcher.position == "Starting Pitcher");
        teamStats.totalStartersPlayer= getTopPlayerByCondition((c) => c.playjson[0].pitcher.position == "Starting Pitcher");

        teamStats.totalRelief = countHomersByCondition((c) => c.playjson[0].pitcher.position == "Relief Pitcher");
        teamStats.totalReliefPlayer= getTopPlayerByCondition((c) => c.playjson[0].pitcher.position == "Relief Pitcher");
      
       
        teamStats.totalGoAhead =  countHomersByCondition((c) =>  (
          (c.playjson[0].gameinfo.inning.type == "Bottom" 
          && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
          && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
          ) ||
          (c.playjson[0].gameinfo.inning.type == "Top" 
          && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
          && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
          ));
        
                    
        teamStats.gameGoAheadPlayer= getTopPlayerByCondition((c) =>  
                            (
                            (c.playjson[0].gameinfo.inning.type == "Bottom" 
                            && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                            ) ||
                            (c.playjson[0].gameinfo.inning.type == "Top" 
                            && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
                            ));

        teamStats.gameWinning = countHomersByCondition((c) =>  
                    (
                    (c.playjson[0].gameinfo.inning.type == "Bottom" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                    ) ||
                    (c.playjson[0].gameinfo.inning.type == "Top" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                    ));

        teamStats.gameWinningPlayer=  getTopPlayerByCondition((c) =>  
                (
                (c.playjson[0].gameinfo.inning.type == "Bottom" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                ) ||
                (c.playjson[0].gameinfo.inning.type == "Top" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                ));
   
   
   

        teamStats.totalTieing =  countHomersByCondition((c) => parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away));
        teamStats.gameGameTiePlayer=  getTopPlayerByCondition((c) => parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away));

        teamStats.longestHR = Enumerable.from(homers)
            .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
            .select(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")"))).firstOrDefault();
        
        teamStats.longestHRPlayer = Enumerable.from(homers)
        .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
        .select(c=> c.playjson[0].batter.name).firstOrDefault();


        var _lastGame = Enumerable.from(games).orderByDescending((c)=> parseInt(c.gamenumber)).firstOrDefault();
        var _lastGameNumber = _lastGame.gamenumber;
        
        const lastGameSinceTypeOfHomer = (condition) => {
          const result = Enumerable.from(gamesANDhomers)
            .where(condition)
            .orderByDescending((c) => parseInt(c.gamenumber))
            .firstOrDefault();
        
          return result ? parseInt(result.gamenumber) : 0;
        };

        const calculateLastGame = (lastgame, homerTypeLastGame) => 
          homerTypeLastGame === 0 ? "None this season" :
          lastgame - homerTypeLastGame === 0 ? "Last Game!" : lastgame - homerTypeLastGame;
        ;
        
        var lastLateGameameNumber = lastGameSinceTypeOfHomer(c=> parseInt(c.playjson[0].gameinfo.inning.number) >=7);        
        var last9thGameGameNumber = lastGameSinceTypeOfHomer(c=> parseInt(c.playjson[0].gameinfo.inning.number) ==9);
        var lastextraGameGameNumber = lastGameSinceTypeOfHomer(c=> parseInt(c.playjson[0].gameinfo.inning.number) >9);
        
        var lastGrandSlamGameGameNumber =lastGameSinceTypeOfHomer(c=> c.playjson[0].playinfo.runs == 4);
        var last3runGameGameNumber     = lastGameSinceTypeOfHomer(c=> c.playjson[0].playinfo.runs == 3);
        var last2runGameGameNumber     = lastGameSinceTypeOfHomer(c=> c.playjson[0].playinfo.runs == 2);        
        var lastMultiRunHRGameGameNumber     = lastGameSinceTypeOfHomer(c=> c.playjson[0].playinfo.runs >1);

        teamStats.GamesSince_GrandSlam  = calculateLastGame(parseInt(_lastGameNumber),parseInt(lastGrandSlamGameGameNumber));
        teamStats.GamesSince_3run = calculateLastGame(parseInt(_lastGameNumber)  , parseInt(last3runGameGameNumber));
        teamStats.GamesSince_2run= calculateLastGame(parseInt(_lastGameNumber) , parseInt(last2runGameGameNumber));
        teamStats.GamesSince_MultiRunHR= calculateLastGame(parseInt(_lastGameNumber)  , parseInt(lastMultiRunHRGameGameNumber));
        teamStats.GamesSince_Back2Back="0"
        teamStats.GamesSince_9thInningHR=calculateLastGame(parseInt(_lastGameNumber)  , parseInt(last9thGameGameNumber));
        teamStats.GamesSince_extraInningHR=calculateLastGame(parseInt(_lastGameNumber)  , parseInt(lastextraGameGameNumber));
        teamStats.GamesSince_LateGameHomer=calculateLastGame(parseInt(_lastGameNumber) ,parseInt(lastLateGameameNumber));
        
                    teamStats.Back2Back = 0;
                    teamStats.Back2BackLastGame = null;
                    var prevGame = 0;
                    var previnning = 0;
                    var prevatbat = 0;
                    var b2bs = [];
                    var totalBack2Back = 0;

      Enumerable.from(gamesANDhomers)
        .orderByDescending((c) => parseInt(c.gamenumber))
        .thenByDescending((c) => parseInt(c.playjson[0].gameinfo.inning.number))
        .thenByDescending((c) => parseInt(c.playjson[0].batter.batorder))
        .forEach(
          (element) => {
          
            if(parseInt(prevGame) == parseInt(element.gamenumber) 
              && parseInt(previnning) == parseInt(element.playjson[0].gameinfo.inning.number) 
              && ((element.playjson[0].batter.batorder == prevatbat-1) || (element.playjson[0].batter.batorder == 1 && prevatbat == 9 )))
                {
                  teamStats.Back2Back = teamStats.Back2Back + 1;                  
                  b2bs.push(element);
                }
          
                prevGame = parseInt(element.gamenumber);
                previnning = parseInt(element.playjson[0].gameinfo.inning.number);
                prevatbat = parseInt(element.playjson[0].batter.batorder);
                
                totalBack2Back++;
          });
          teamStats.Back2BackLastGame = calculateLastGame(parseInt(_lastGameNumber) ,parseInt(b2bs[0]?.gamenumber ?? 0));

   var viewByQuery= Enumerable.from(homers).select(function (c) {
    return { runs: c.playjson[0].playinfo.runs}}).distinct(c=> c.runs).toArray();
    
    viewByQuery.forEach(function (p){
        p.total = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).count();
        p.homers = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).toArray();
    });

    var mostSolo = Enumerable.from(viewByQuery).where(c=> c.runs == 1).select(c=> c.homers).firstOrDefault();
        mostSolo  = Enumerable.from(mostSolo)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
    
     var  most2hr = Enumerable.from(viewByQuery).where(c=> c.runs == 2).select(c=> c.homers).firstOrDefault();
            most2hr  = Enumerable.from(most2hr)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
            var  most3hr = Enumerable.from(viewByQuery).where(c=> c.runs == 3).select(c=> c.homers).firstOrDefault();
            most3hr  = Enumerable.from(most3hr)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
            var  mostgrandslam = Enumerable.from(viewByQuery).where(c=> c.runs == 4).select(c=> c.homers).firstOrDefault();
            mostgrandslam  = Enumerable.from(mostgrandslam)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
    
            var playerOnlyHomers = Enumerable.from(homers).join(games, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right}))
                    .orderByDescending(c=> parseInt(c.gamenumber)) .toArray();
                 
      
                var longestHomerGameHitStreak = 1;
                var currentlongestHomerGameHitStreak = 1;
                var lastgame = 0;
                playerOnlyHomers.map((homerHit, i) => {  
                  
                  if(parseInt(homerHit.gamenumber) == lastgame -1)
                    currentlongestHomerGameHitStreak +=1;
                  else
                  {
                    if (currentlongestHomerGameHitStreak > longestHomerGameHitStreak)
                      longestHomerGameHitStreak  = currentlongestHomerGameHitStreak;
      
                      currentlongestHomerGameHitStreak=1;
                  }
      
                  lastgame=parseInt(homerHit.gamenumber)
      
                });



        const renderStatsRows = (rows) =>
          rows.map(({ label, value }) => (
            <tr key={label}>
              <th colSpan="2">{label}</th>
              <td>{value}</td>
            </tr>
          ));

          const renderLeadersRows = (rows) =>
            rows.map(({ label, value,leadername }) => (
              <tr key={label}>
              <th colSpan={"2"}>{label}</th>                            
              <td>{value}</td>
              <td>{leadername}</td>
            </tr>
            ));
          

          const teamStatsRows = [
            { label: "Last 3 games", value: teamStats.last3games },
            { label: "Last 5 games", value: teamStats.last5games },
            { label: "Last 10 games", value: teamStats.last10games },

            { label: "Last 20 games", value: teamStats.last20games },
            { label: "Last 30 games", value: teamStats.last30games },
            { label: "vs. RHP", value: teamStats.totalRHP },
            { label: "vs. LHP", value: teamStats.totalLHP },

            { label: "vs. Starters", value: teamStats.totalStarters},
            { label: "vs. Relief", value: teamStats.totalRelief },
            { label: "Tieing", value: teamStats.totalTieing},
            { label: "Go Ahead", value: teamStats.totalGoAhead },
            { label: "Game Winning", value: teamStats.gameWinning },
            { label: "Total Back-2-Backs", value: teamStats.Back2Back },
            { label: "Longest HR Game Streak", value: longestHomerGameHitStreak },
          ];
          const teamLeadersRows = [
            { label: "Longest", value: teamStats.longestHR, leadername:teamStats.longestHRPlayer },
            { label: "Most Solo HR", value: mostSolo?.count??0, leadername:mostSolo?.key ?? "No one" },
            { label: "Most 2-run HR", value: most2hr?.count??0, leadername:most2hr?.key ?? "No one"},
            { label: "Most 3-run HR", value: most3hr?.count??0, leadername:most3hr?.key ?? "No one" },
            { label: "Most Grand Slams", value: mostgrandslam?.count??0, leadername:mostgrandslam?.key ?? "No one" },
            { label: "Most Game Tieing HR", value: teamStats.gameGameTiePlayer?.count??0, leadername:teamStats.gameGameTiePlayer?.key ?? "No one" },
            { label: "Most Go Ahead HR", value: teamStats.gameGoAheadPlayer?.count??0, leadername:teamStats.gameGoAheadPlayer?.key  ?? "No one"},
            { label: "Most Game Winning HR", value: teamStats.gameWinningPlayer?.count??0, leadername:teamStats.gameWinningPlayer?.key  ?? "No one"},            
            
            { label: "Most Off Starters", value: teamStats.totalStartersPlayer?.count??0, leadername:teamStats.totalStartersPlayer?.key ?? "No one"},
            { label: "Most Off Relief Pitching", value: teamStats.totalReliefPlayer?.count??0, leadername:teamStats.totalReliefPlayer?.key  ?? "No one"},
            { label: "Most Off RHP", value: teamStats.totalRHPPlayer?.count??0, leadername:teamStats.totalRHPPlayer?.key  ?? "No one"},
            { label: "Most Off LHP", value: teamStats.totalLHPPlayer?.count??0, leadername:teamStats.totalLHPPlayer?.key  ?? "No one"},
            { label: "Most With Full Count", value: teamStats.totalFullCount?.count??0, leadername:teamStats.totalFullCount?.key  ?? "No one"},           
          ];
          const teamLastGameSince = [
            { label: "Last Multi-run HR", value:  teamStats.GamesSince_MultiRunHR },
            { label: "Last Grand Slam", value:  teamStats.GamesSince_GrandSlam },
            { label: "Last 3-run HR", value: teamStats.GamesSince_3run },

            { label: "Last 2-run HR", value:  teamStats.GamesSince_2run },
            { label: "Last Late Game (7,8,9) HR", value: teamStats.GamesSince_LateGameHomer},

            { label: "Last 9th Inning HR", value: teamStats.GamesSince_9thInningHR},
            { label: "Last Extra inning HR", value: teamStats.GamesSince_extraInningHR},
            { label: "Last Back 2 Back", value:teamStats.Back2BackLastGame},
            

          ];
                       
    return (<div>
    <div className={"timeline-item"}>
              <div className={"timeline-player-name"}><span>Team Stats</span></div>
            <a href={"#"} className={"timeline-player-link"}>
                <img alt={"avatar"} src={"/img/philadelphia-phillies-vector-logo.png"} />
            </a>
           
                    
          <div className={"timeline-item-content-team"}>
            
              <h2>{gamesANDhomers.length} total homers in {games.length} games! </h2>
              <h3>Last homerrun was {teamStats.lastHomer.playjson[0].batter.name} on {teamStats.lastHomer.date}</h3>
              <div className={"timeline-item-content-inside"}>
                <div className={"timeline-item-content-left"}>                                       
                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Home Run Stats</header>
                        <table className={"performance-facts__table--small small-info homerunwidth"}>                                      
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Team Totals</td>             
                          </tr>
                        </thead>
                        <tbody>
                            {renderStatsRows(teamStatsRows)}                          
                        </tbody>
                      </table>
                      </section>
                      <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Team Leaders</header>
                    <table className={"performance-facts__table--small small-info homerunwidth"}>  
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Team Leader</td>             
                          </tr>
                        </thead>
                        <tbody>
                        {renderLeadersRows(teamLeadersRows)}                        
                        </tbody>
                      </table>                      
                  </section>

                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Last Game Since</header>
                    <table className={"performance-facts__table--small small-info homerunwidth"}>                      
                        
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Games Since</td>             
                          </tr>
                        </thead>
                        <tbody>
                        {renderStatsRows(teamLastGameSince)} 
                        

                          

                          
                          
                        </tbody>
                      </table>                      
                  </section>
                </div>
                
                </div>
          </div>
                         
</div>   </div>
)
}
export default TeamStats