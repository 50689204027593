import React from 'react'
import { useParams   } from "react-router-dom";
import TopSocialLinks from './TopSocialLinks';
import TopHeaderMenu from "./TopHeaderMenu";
import SideBarPlayers from "./SideBarPlayers";
import GameList from "./GameList";


const Index = ({appState}) => { 
    const routeParams = useParams();
   
        return (
        <div id={"root"}>             
               {window.ViewGameGameID(routeParams.gameId)}
              <TopSocialLinks />
              <TopHeaderMenu />
              <div id={"main"}>			
                  <div id={"home"} className={"group"}>
                      <div id={"timeline"} className={"col span_2_of_3"}>  
                      {routeParams.gameId == undefined ? <div className={"timeline-header"}><span>Viewing 10 most recent games</span></div> : ""}                                                             
                          <GameList gameid={routeParams.gameId} recentgames={10} />   
                      </div>
                      <div id={"sidebar"} className={"col span_1_of_3"}>
                          <div className={"widget widget_search"}>                             
                          </div>                       
                          <SideBarPlayers />
                      </div>
                  </div>                
              </div>
              <footer id={"footer"} className={"clearfix"}></footer>
      </div>
  );
}

export default Index

