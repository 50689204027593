
import React, { Component }  from "react";
import "./App.css";
import { Provider} from 'react-redux';
import { store ,persistor} from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Index from "./components/index";
import player from "./components/player";
import Team from "./components/team";
import Recent from "./components/recent";
import CheckCache from "./components/checkcache";

class App extends Component {
    constructor(props) {
        super(props);                     
    }        
    
    render() { 
        return (
            <Provider store={store}>
                <CheckCache />
                 <PersistGate loading={null} persistor={persistor}>
                 <Router basename={"https://www.phillieshomeruns.com/"}>
                    <Switch>
                    <Route exact path="/" component={Index} />
                    <Route exact path="/player:playerId" component={player} />                    
                    <Route exact path="/listplayers" component={player} />
                    <Route exact path="/game:gameId" component={Index} />
                    <Route exact path="/listgames" component={player} />
                    <Route exact path="/team" component={Team} />
                    <Route exact path="/recent:recentgames" component={Recent} /> 
                    </Switch>
                </Router>
            </PersistGate>
            </Provider>
        );
    }
}



export default App;
