import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'


// Utility function to add ordinal suffix
function ordinalSuffix(i) {
  const j = i % 10, k = i % 100;
  if (j === 1 && k !== 11) return i + "st";
  if (j === 2 && k !== 12) return i + "nd";
  if (j === 3 && k !== 13) return i + "rd";
  return i + "th";
}

const HomeRun = ({homer, gameinfo, gamesANDhomers}) => {
  
  const { games } = useSelector(state => state.games);
  const { homers } = useSelector(state => state.homers);


 // Merge games and homers if not provided
 if (!gamesANDhomers) {
  gamesANDhomers = Enumerable.from(games)
    .join(homers, g => parseInt(g.gameid), h => parseInt(h.gameid), (g, h) => ({ ...g, ...h }))
    .toArray();
}       
 
const awayteam =  homer.playjson[0].gameinfo.inning.type=="Top" ? gameinfo.awayteam : gameinfo.hometeam;   
const hometeam =  homer.playjson[0].gameinfo.inning.type=="Bottom" ? gameinfo.awayteam : gameinfo.hometeam; 

const batter = homer.playjson[0].batter;
const playInfo = homer.playjson[0].playinfo;
const gameInfo = homer.playjson[0].gameinfo;
const inning = gameInfo.inning;
const score = gameInfo.score;


 // Find the last home run hit by the same batter before the current game
 const lastHomer = Enumerable.from(gamesANDhomers)
 .where(c => parseInt(c.gameid) !== parseInt(homer.gameid) &&
   c.playjson[0].batter.id === batter.id &&
   parseInt(c.gamenumber) < parseInt(gameinfo.gamenumber))
 .orderByDescending(c => parseInt(c.gamenumber))
 .thenByDescending(c => parseInt(c.playjson[0].gameinfo.inning.number))
 .firstOrDefault();

   
 const lastHitHomerun = lastHomer ? parseInt(gameinfo.gamenumber) - parseInt(lastHomer.gamenumber) : 0;

 // Extract home run distance
 const distance = parseInt(playInfo.text.match(/\((\d+) feet\)/)?.[1] || 0);

  // Conditions for special home run events
 const tie = parseInt(score.home) === parseInt(score.away);

 const goAhead = (inning.type === "Bottom" && score.home > score.away &&
      (score.home - parseInt(playInfo.runs)) <= score.away) ||
      (inning.type === "Top" && score.home < score.away &&
      (score.away - parseInt(playInfo.runs)) <= score.home);

 const win = (inning.type === "Bottom" && parseInt(inning.number) >= 9 && score.home > score.away &&
      (score.home - parseInt(playInfo.runs)) <= score.away) ||
      (inning.type === "Top" && parseInt(inning.number) >= 9 && score.home < score.away &&
      (score.away - parseInt(playInfo.runs)) <= score.home);
  

      // Determine border class based on special conditions
  let highlightCount = 0;

  if (distance > 400) highlightCount++;
  if (playInfo.runs === 4) highlightCount++;
  if (goAhead || tie) highlightCount++;
  if (win) highlightCount++;
  
  const topRightBorderClass = `timeline-player-count timeline-player-count${highlightCount}`;

return (
    <div className={"timeline-item"}>
        <div className={"timeline-player-name"}><span title={batter.name}>{batter.name}</span></div>
        <a href={`/player${batter.id}`} className="timeline-player-link">
          <img alt="avatar" src={`/images/players/${batter.id}.png`} />
        </a>
        <a href={`/player${batter.id}`} className="timeline-item-link">
            <div className={"timeline-item-content"}>                                  
            <h2>{batter.name} hits {ordinalSuffix(homer.playerstats[0].homeruntotalstonow.total)}</h2>
            <h3>{playInfo.text}</h3>
                <div className={"timeline-item-content-inside"}>
                  <div className={"timeline-item-content-left"}>                       
                    <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>
                          <p>{inning.type} {inning.displayValue}</p>
                      </header>
                      <table className={"performance-facts__table"}>
                        <thead>      
                          <tr>
                            <th colSpan={"3"} className={"small-info"}>
                              Pitcher:  {homer.playjson[0].pitcher.name} {homer.playjson[0].pitcher.throws}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>
                              <b>Pitch </b>
                              {playInfo.pitchType} {playInfo.pitchVelocity}mph
                            </th>
                            <td></td>
                          </tr>
                          
                          <tr>
                            <th colSpan={"2"}>
                              <b>Outs </b>
                              {playInfo.outs}         
                            </th>
                            <td></td>
                          </tr>                          
                          <tr>
                            <th colSpan={"2"}>
                              <b>Pitch Count</b>          
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                              Balls
                            </th>
                            <td> {playInfo.pitchcount.balls}
                            </td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                              Strikes
                            </th>
                            <td> {playInfo.pitchcount.strikes}
                            </td>
                          </tr>   
                          <tr>
                            <th colSpan={"2"}>
                              <b>Score after HR</b>          
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                            Phillies
                            </th>
                            <td> {gameInfo.inning.type=="Bottom" ? gameInfo.score.home : gameInfo.score.away}
                            </td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                            {gameInfo.inning.type=="Bottom" ? awayteam : hometeam}                            
                            </th>
                            <td> {gameInfo.inning.type=="Bottom" ? gameInfo.score.away : gameInfo.score.home}
                            </td>
                          </tr>   
                            <tr className={"thick-row"}>
                            <td colSpan={"3"} className={"small-info"}>  
                              Games Since last: {lastHitHomerun == 0 ?  "FIRST HR!": lastHitHomerun}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                  <div className={"timeline-item-content-right"}>  
                    <section className={"performance-facts"}>
                        <header className={"performance-facts__header"}>Home Run Stats</header>
                          <table className={"performance-facts__table--small small-info homerunwidth"}>
                                        
                          <thead>     
                            <tr>
                              <td colSpan={"4"}>Home Runs</td>             
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan={"2"}>Last 3 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last3games}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Last 5 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last5games}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Last 10 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last10games}</td>
                              <td></td>
                            </tr>
                            
                          </tbody>
                        </table>
                          <table className={"performance-facts__table--small small-info homerunwidth"}>
                          <thead>     
                            <tr>
                              <td colSpan={"4"}>As of this game totals</td>        
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan={"2"}>Solo HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.solo}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>2 run HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow["2run"]}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>3 run HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow["3run"]}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Grandslam</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.grandslam}</td>
                              <td></td>
                            </tr>
                              <tr>
                              <th colSpan={"2"}>TOTAL</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.total}</td>
                              <td></td>
                            </tr>
                            
                          </tbody>
                        </table>                      
                    </section>
                  </div>
              </div>
            </div>
        </a>
        {(distance > 400 || playInfo.runs === 4 || goAhead || tie || win) && (
        <div className={topRightBorderClass}>
          <span>
            {distance > 400 && <img title={`400+ ft homer (${distance} feet!)`} src="../images/icon/ball400plus.png" />}
            {playInfo.runs === 4 && <img title="Grand Slam!" src="../images/icon/grandslam.png" />}
            {(goAhead || tie) && <img title="Game tying or ahead homer!" src="../images/icon/tiewinning.png" />}
            {win && <img title="Game winning homer!" src="../images/icon/ringthebell.png" />}
          </span>
        </div>
      )}
    </div>
   
)
}
export default HomeRun
