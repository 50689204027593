import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {  FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER} from 'redux-persist'


import gamesReducer from '../reducers/gamesReducer'
import playersReducer from '../reducers/playersReducer';
import homersReducer from '../reducers/homersReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['games', 'players', 'homers'], // Only persist the reducers you need
}

const rootReducer = combineReducers({ 
  games: gamesReducer,
  players: playersReducer,
  homers: homersReducer
})


const persistedReducer =  persistReducer(persistConfig, rootReducer);

export const store =  configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions:  [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
       ignoredPaths: ['homers.lastupdated']
      },
    }),
})

export const persistor = persistStore(store);