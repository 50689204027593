import React, { useMemo } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { HideShowNoHomers } from '../reducers/gamesReducer';
import HomeRun from "./HomeRun";
import Enumerable from '../app/enumerable';

const GameDay = ( {gamenumber, gameday, gameid, gameinfo, playerId = 0, homer,showHomers, gamesANDhomers}) => {
  
  const dispatch = useDispatch();
  
  const fetchedHomers = useSelector(state => state.homers.homers);

  const homers = useMemo(() => {
    if (homer !== null) return [homer];  // If a single homer is passed, return it as an array.
    return Enumerable.from(fetchedHomers)
      .where(c => c.gameid === gameid && (c.playjson[0].batter.id === playerId || playerId === 0))
      .orderByDescending(c => c.playjson[0].playinfo.id)
      .toArray();
  }, [fetchedHomers, gameid, playerId, homer]);

  const NoHomersComponent = () => (
    <div homers="0" id={gamenumber} style={{ display: showHomers ? 'block' : 'none' }}>
      <div className="timeline-date">
        <span className="gamenumber">
          <a className="linktogame" href={`/game${gamenumber}`}>Game #{gamenumber}</a> {gameinfo.title}
        </span>
        <span className="gameday">{gameday}</span>
      </div>
      <div className="timeline-nohomers">
        <span>No home runs.</span>
        <span id="hideshow">
          <a style={{ color: "white" }} href="#" onClick={() => dispatch(HideShowNoHomers(!showHomers))}>
            {showHomers ? 'Hide' : 'Show'}
          </a>
        </span>
      </div>
    </div>
  );

    const SoloHomerComponent = () => <HomeRun gamesANDhomers={gamesANDhomers} homer={homer} gameinfo={gameinfo} />;

    const MultiHomerComponent = () => (
      <div key={gameid}>
        <div className="timeline-date">
          <span className="gamenumber">
            <a className="linktogame" href={`/game${gamenumber}`}>Game #{gamenumber}</a> {gameinfo.title}
          </span>
          <span className="gameday">{gameday}</span>
        </div>
        {homers.map((homer) => (
          <div key={homer.playid}>
            <HomeRun gamesANDhomers={gamesANDhomers} homer={homer} gameinfo={gameinfo} />
          </div>
        ))}
      </div>
    );

     return ( 
      homers.length == 0 ?  <NoHomersComponent/> : (homers.constructor != Array  ?  <SoloHomerComponent/>: <MultiHomerComponent/>)
     )
}

export default GameDay
