import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'


const SideBarPlayers = () => {
    var homers = useSelector(state => state.homers.homers);
    var groupedList = Enumerable.from(homers)
    .groupBy(
        c => c.playjson[0].batter.id,  // Key selector (group by batter ID)
        c => ({
            id: c.playjson[0].batter.id,
            batter: c.playjson[0].batter.name,
            photo: c.playjson[0].batter.photo
        }), 
        (key, group) => ({
            id: key,
            batter: group.first().batter,
            photo: group.first().photo,
            count: group.count() // Count occurrences of each batter
        })
    )
    .toArray();

         console.log(groupedList);
    return (
            <div className={"widget widget-social"}>
                <div><span>
                <h3 className={"widget-title"}>Whats it mean?</h3>
                    <img height={40} width={40} title={"400+ homer"} src={"../images/icon/ball400plus.png"}/> 400+ foot homerun<br/><br/> 
                    <img height={40} width={40} title={"Grand Slam"} src={"../images/icon/grandslam.png"}/> Grand Slam homerun<br/><br/> 
                    <img height={40} width={40} title={"Game tieing or ahead homer!"} src={"../images/icon/tiewinning.png"}/> Game tieing or ahead homer!<br/><br/> 
                    <img height={40} width={40} title={"Game winning homer!"} src={"../images/icon/ringthebell.png"}/> Game winning homer!<br/>             <br/>        
                </span></div><br/> <br/> 
                <h3 className={"widget-title"}>Players</h3>
                <ul className={"social-buttons-list clearfix"}>
                { Enumerable.from(groupedList).orderByDescending(c=> parseInt(c.count)).toArray().map((player) => (
                    <li key={player.id}  style={{ position: "relative", listStyle: "none", display: "inline-block" }}><a className={"social-button"} href={"/player" + player.id}>
                        
                        <img src={"/images/players/" + player.id + ".png"} style={{height:"85px", width:"90px"}}/></a>
                        <div
                    style={{
                        width:"100%",
                        position: "absolute",
                        bottom: "0px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#6BACE4",
                        color:"black", 
                        padding: "2px 4px",
                        borderRadius: "5px 5px 0 0", // Rounded top corners only
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        display: "flex",      // Makes centering easier
                        justifyContent: "center", // Horizontally center
                        alignItems: "center",  // Vertically center
                        borderBottom: "2px solid black" // Add bottom black border
                    }}
                >
        {player.count} HR's
    </div>
                        </li>    
                                     
                    )) 
                }      
                    
                </ul>
            </div>)
}

export default SideBarPlayers
