import React, { useEffect ,useMemo }  from 'react'
 
import { useParams } from "react-router-dom";
import { useSelector} from 'react-redux';

import TopSocialLinks from './TopSocialLinks';
import TopHeaderMenu from "./TopHeaderMenu";
import SideBarPlayers from "./SideBarPlayers";
import PlayerStats from "./playerstats";
import GameList from "./GameList";
import Enumerable from '../app/enumerable';


const Player  = () => { 
    const { playerId } = useParams();
    const homers = useSelector(state => state.homers.homers);

    const returnPlayer = useMemo(() => {
        if (!homers || homers.length === 0) return null;

        const playerData = Enumerable.from(homers).where(c => c.playjson[0].batter.id === playerId).firstOrDefault();
        if (!playerData) return null;

        const { batter, playjson } = playerData.playjson[0];

        const playerStats = {
            id: batter.id,
            batter: batter.name,
            photo: batter.photo,
            total: 0,
            totalRHP: 0,
            totalLHP: 0,
            totalStarters: 0,
            totalRelief: 0,
            totalGoAhead: 0,
            gameWinning: 0,
            totalTieing: 0,
            longestHR: 0
        };

        const playerHomers = Enumerable.from(homers).where(c => c.playjson[0].batter.id === playerId);

        playerStats.total = playerHomers.count();
        playerStats.totalRHP = playerHomers.where(c => c.playjson[0].pitcher.throws === "RHP").count();
        playerStats.totalLHP = playerHomers.where(c => c.playjson[0].pitcher.throws === "LHP").count();
        playerStats.totalStarters = playerHomers.where(c => c.playjson[0].pitcher.position === "Starting Pitcher").count();
        playerStats.totalRelief = playerHomers.where(c => c.playjson[0].pitcher.position === "Relief Pitcher").count();
        
        playerStats.totalGoAhead = playerHomers.where(c => {
            const { inning, score } = c.playjson[0].gameinfo;
            const runs = parseInt(c.playjson[0].playinfo.runs);

            if (inning.type === "Bottom") {
                return parseInt(score.home) > parseInt(score.away) && (parseInt(score.home) - runs < parseInt(score.away));
            } else {
                return parseInt(score.home) < parseInt(score.away) && (parseInt(score.away) - runs <= parseInt(score.home));
            }
        }).count();

        playerStats.gameWinning = playerHomers.where(c => {
            const { inning, score } = c.playjson[0].gameinfo;
            const runs = parseInt(c.playjson[0].playinfo.runs);

            if (parseInt(inning.number) >= 9) {
                if (inning.type === "Bottom") {
                    return parseInt(score.home) > parseInt(score.away) && (parseInt(score.home) - runs < parseInt(score.away));
                } else {
                    return parseInt(score.home) < parseInt(score.away) && (parseInt(score.away) - runs <= parseInt(score.home));
                }
            }
            return false;
        }).count();

        playerStats.totalTieing = playerHomers.where(c => {
            const { score } = c.playjson[0].gameinfo;
            return parseInt(score.home) === parseInt(score.away);
        }).count();

        playerStats.longestHR = playerHomers
            .orderByDescending(c => {
                const text = c.playjson[0].playinfo.text;
                return parseInt(text.substring(text.indexOf("(") + 1, text.lastIndexOf(")")));
            })
            .select(c => {
                const text = c.playjson[0].playinfo.text;
                return text.substring(text.indexOf("(") + 1, text.lastIndexOf(")"));
            })
            .firstOrDefault();

        return playerStats;
    }, [homers, playerId]);

    if (!returnPlayer) {
        return <div>Player data not found</div>;
    }

    return (
        <div id="root">
            
            {window.ViewPlayerPlayerID(returnPlayer.batter)}
            <TopSocialLinks />
            <TopHeaderMenu />

            <div id="main">			
                <div id="home" className="group">
                    <div id="timeline" className="col span_2_of_3">     
                        <div className="timeline-header">
                            <span>Viewing {returnPlayer.batter}'s home runs!</span>
                        </div>      
                        <PlayerStats player={returnPlayer} />                                                                                
                        <GameList playerId={playerId} recentgames={163} />  
                    </div>
                    <div id="sidebar" className="col span_1_of_3">
                        <div className="widget widget_search"></div>                       
                        <SideBarPlayers />
                    </div>
                </div>                
            </div>

            <footer id="footer" className="clearfix"></footer>
        </div>
    );
}

export default Player;