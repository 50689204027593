import React from 'react'
import { useSelector} from 'react-redux';
import Enumerable from '../app/enumerable';
import HomeRun from "./HomeRun";

import TeamStats from './TeamStats';
import TopSocialLinks from './TopSocialLinks';
import TopHeaderMenu from "./TopHeaderMenu";
import SideBarPlayers from "./SideBarPlayers";
import PlayerStats from "./playerstats";



function ExtractText(playjson) {
    console.log(playjson?.playinfo?.text);
    const text = playjson?.playinfo?.text || ''; // Safely access the text field

    // Find the position of ' to ' (start of desired substring)
    const toIndex = text.indexOf(' to ');

    if (toIndex === -1) {
        return ''; // Return empty if 'to ' is not found
    }

    // Get the substring starting right after 'to '
    const startPos = toIndex + 4;

    // Check if '(' exists
    const parenIndex = text.indexOf('(', startPos);

    // Check for the first occurrence of '.' or ','
    const dotIndex = text.indexOf('.', startPos);
    const commaIndex = text.indexOf(',', startPos);

    // Determine the end position
    let endPos = text.length;

    if (parenIndex !== -1) {
        // If '(' exists, extract text up to '('
        endPos = parenIndex;
    } else {
        // If no '(', use the earliest of '.' or ',' if either exists
        const validIndices = [dotIndex, commaIndex].filter(index => index >= 0);
        if (validIndices.length > 0) {
            endPos = Math.min(...validIndices); // Get the earliest of '.' or ','
        }
    }

    // Extract the relevant portion of the text
    let extractedText = text.substring(startPos, endPos).trim();

    // Remove '.' from the extracted text
    extractedText = extractedText.replace('.', '');

    return extractedText;
}

function fix_homer_type(i) {
    
    if (i == 1) {
        return "Solo";
    }
    if (i == 2) {
        return "2-run homerun";
    }
    if (i==3) {
        return "3-run homerun";
    }
    return "Grand Slam!";
  }
  
  function get_pitchVelocity_group(i) {
    if (i < 70) {
        return "< 70";
    }else if  (i < 80) {
        return "70-79";
    }
    else if  (i < 90) {
        return "80-89";
    }
    else if  (i < 100) {
        return "90-99";
    } else {
        return "100+";
    }
  }
  function get_distance_group(i) {
    if (i < 30) {
        return "< 300 feet";
    }else if  (i < 350) {
        return "300-350 feet";
    }
    else if  (i < 400) {
        return "350-400 feet";
    }
    else if  (i < 450) {
        return "400-450 feet";
    }  else if  (i < 500) {
        return "450-500 feet";
    }else {
        return "500+ feet";
    }
  }
function ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }
const Team = ({appState}) => { 
    var homers = useSelector(state => state.homers.homers);
    var games = useSelector(state => state.games.games);  


    const isViewBy  =window.location.search.includes("viewBy");
    var PageCom;
    var SideBarInfo = () => { return ("")};
    if(isViewBy){
        const viewBy = window.location.search.replace("?viewBy=","");
        
        var viewByQuery;
        switch(viewBy) {
            case 'innings':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { inning: c.playjson[0].gameinfo.inning.number}}).distinct(c=> c.inning).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].gameinfo.inning.number) == parseInt(p.inning)).count();
                        p.homers = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].gameinfo.inning.number) == parseInt(p.inning)).toArray();
                    });
                    var SideBarInfo = () => {
                        return (

                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Inning totals</p>
                            </div>
                           
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                
                               {window.GA_reun_event("innings")}     
                            {  
                                Enumerable.from(viewByQuery).orderBy(c=> parseInt(c.inning)).toArray().map((inning) => 
                                {
                                    return ( 
                                        <tr key={inning.inning + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{ordinal_suffix_of(inning.inning)} </b>                                            
                                        </th>
                                        <td>{inning.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};
                    
                    PageCom = () => { return (Enumerable.from(viewByQuery).orderBy(c=> parseInt(c.inning)).toArray().map((inning) => (
                        <div key={inning.inning}>
                        <div className={"timeline-inning"}><span>{ordinal_suffix_of(inning.inning)} inning</span></div> 
                        { Enumerable.from(inning.homers).orderByDescending(c=> new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                break;
            case 'grandslams':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { runs: c.playjson[0].playinfo.runs}}).distinct(c=> c.runs).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).count();
                        p.homers = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).toArray();
                    });
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                                <script>GA_reun_event('grandslams');</script>                                
                            <div className={"performance-facts__header"}>
                                <p>By Home Type totals</p>
                            </div>
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                {window.GA_reun_event("grandslams")}  
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> parseInt(c.runs)).toArray().map((runs) => 
                                {
                                    return ( 
                                        <tr key={runs.runs + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{fix_homer_type(parseInt(runs.runs))}</b>                                            
                                        </th>
                                        <td>{runs.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { return (Enumerable.from(viewByQuery).where(c=> parseInt(c.runs) == 4).toArray().map((slams) => (
                        <div key={slams.runs}>
                        <div className={"timeline-inning"}><span>{slams.total} Grand Slams!</span></div> 
                        { Enumerable.from(slams.homers).orderByDescending(c=> new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'gamewin':
                viewByQuery= Enumerable.from(homers).where(c=> 
                    (
                    (c.playjson[0].gameinfo.inning.type == "Bottom" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                    ) ||
                    (c.playjson[0].gameinfo.inning.type == "Top" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                    )).toArray();
                                        
                   
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Home Type totals</p>
                            </div>
                            {window.GA_reun_event("gamewin")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>
                             
                                        <tr>                                      
                                        <th colSpan={"2"}>
                                            <b>Game Winning Homeruns</b>                                            
                                        </th>
                                        <td>{Enumerable.from(viewByQuery).count()} </td>
                                        </tr>
                                 
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { 
                        return ( 
                            <div>
                            <div className={"timeline-inning"}><span>Game Winning Homeruns</span></div>                            
                            {
                                    Enumerable.from(viewByQuery).orderByDescending(c=> new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                                    {
                                        var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                                        {
                                        return (<div key={homer.playid}> 
                                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                                <HomeRun homer={homer} gameinfo={gameinfo}/></div>
                                            ) 
                                        }  
                                    })
                            }
                            </div>
                            )};
                        
                       
                    break;
            case 'gametie':
                viewByQuery= Enumerable.from(homers).where(c=> 
                    parseInt(c.playjson[0].gameinfo.score.home) == parseInt(c.playjson[0].gameinfo.score.away)).toArray();
                                        
                   
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Home Type totals</p>
                            </div>
                            {window.GA_reun_event("gametie")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                        <tr>                                      
                                        <th colSpan={"2"}>
                                            <b>Game Tieing Homeruns</b>                                            
                                        </th>
                                        <td>{Enumerable.from(viewByQuery).count()} </td>
                                        </tr>                                 
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { 
                        return ( 
                            <div>
                            <div className={"timeline-inning"}><span>Game Tieing Homeruns</span></div>                            
                            {
                                    Enumerable.from(viewByQuery).orderByDescending(c=> new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                                    {
                                        var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                                        {
                                        return (<div key={homer.playid}> 
                                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                                <HomeRun homer={homer} gameinfo={gameinfo}/></div>
                                            ) 
                                        }  
                                    })
                            }
                            </div>
                            )};
                        
                       
                    break;
            case 'longest':
                

                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { distance:c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")).replace(" feet",""),
                        distanceGrouping: get_distance_group(parseInt(c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")).replace(" feet","")))}}).distinct(c=> c.distance).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")).replace(" feet","")) == p.distance).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")).replace(" feet","")) == p.distance).toArray();
                    });
                    
                    var SideBarInfo = () => {
                        
                            
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>Distance totals</p>
                            </div>
                            {window.GA_reun_event("longest")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                { 
                           
                           Enumerable.from(viewByQuery).groupBy( c=> c.distanceGrouping, element => element, (key, items) => ({key, total: (items.toArray()).length})).toArray().map((distance) => 
                               {
                                   return ( 
                                       <tr key={distance.key + "sidebartotals"}>                                      
                                       <th colSpan={"2"}>
                                           <b>{distance.key} </b>                                            
                                       </th>
                                       <td>{distance.total} </td>
                                       </tr>
                                   )
                               
                               })
                           }
                           </tbody>
                            </table>
                            <div className={"performance-facts__header"}>
                                <p>By distance</p>
                            </div>
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                
                            { 
                            
                            Enumerable.from(viewByQuery).orderBy(c=> (c.distance)).toArray().map((distance) => 
                            {
                                return ( 
                                    <tr key={distance.distance + "sidebartotals"}>                                      
                                    <th colSpan={"2"}>
                                        <b>{distance.distance} </b>                                            
                                    </th>
                                    <td>{distance.total} </td>
                                    </tr>
                                )
                            
                            })
                        }
                            
                            
                            </tbody>
                            </table>
                            </section>
                            )};

                          
                    PageCom = () => { return (Enumerable.from(viewByQuery).orderByDescending(c=> parseInt(c.distance)).toArray().map((distance) => (
                        <div key={distance.distance}>
                        <div className={"timeline-inning"}><span><b>{(distance.total)}</b> went {(distance.distance)} feet</span></div> 
                        { Enumerable.from(distance.homers).orderByDescending(c=> parseInt(c.gameid)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            
            case 'offLHP':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { throws: c.playjson[0].pitcher.throws}}).distinct(c=> c.throws).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.throws) == (p.throws)).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.throws) == (p.throws)).toArray();
                    });
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Pitcher Throwing</p>
                            </div>
                            {window.GA_reun_event("offLHP")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                    
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> (c.throws)).toArray().map((throws) => 
                                {
                                    return ( 
                                        <tr key={throws.throws + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{throws.throws}</b>                                            
                                        </th>
                                        <td>{throws.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { return (Enumerable.from(viewByQuery).where(c=> (c.throws) == "LHP").toArray().map((throws) => (
                        <div key={throws.throws}>
                        <div className={"timeline-inning"}><span>{throws.total} against LHP!</span></div> 
                        { Enumerable.from(throws.homers).orderByDescending(c=>  new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'offRHP':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { throws: c.playjson[0].pitcher.throws}}).distinct(c=> c.throws).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.throws) == (p.throws)).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.throws) == (p.throws)).toArray();
                    });
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Pitcher Throwing</p>
                            </div>
                            {window.GA_reun_event("offRHP")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>                                    
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> (c.throws)).toArray().map((throws) => 
                                {
                                    return ( 
                                        <tr key={throws.throws + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{throws.throws}</b>                                            
                                        </th>
                                        <td>{throws.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { return (Enumerable.from(viewByQuery).where(c=> (c.throws) == "RHP").toArray().map((throws) => (
                        <div key={throws.throws}>
                        <div className={"timeline-inning"}><span>{throws.total} against RHP!</span></div> 
                        { Enumerable.from(throws.homers).orderByDescending(c=>   new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'offStarters':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { position: c.playjson[0].pitcher.position}}).distinct(c=> c.position).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.position) == (p.position)).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.position) == (p.position)).toArray();
                    });
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Starters/Relief</p>
                            </div>
                            {window.GA_reun_event("offStarters")}  
                            <table className={"performance-facts__table"}> 
                                <tbody>                                    
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> (c.position)).toArray().map((position) => 
                                {
                                    return ( 
                                        <tr key={position.position + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{position.position}</b>                                            
                                        </th>
                                        <td>{position.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                    PageCom = () => { return (Enumerable.from(viewByQuery).where(c=> (c.position) == "Starting Pitcher").toArray().map((position) => (
                        <div key={position.position}>
                        <div className={"timeline-inning"}><span>{position.total} against Starters!</span></div> 
                        { Enumerable.from(position.homers).orderByDescending(c=>  new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'offRelief': 
            viewByQuery= Enumerable.from(homers).select(function (c) {
                return { position: c.playjson[0].pitcher.position}}).distinct(c=> c.position).toArray();
                
                viewByQuery.forEach(function (p){
                    p.total = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.position) == (p.position)).count();
                    p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].pitcher.position) == (p.position)).toArray();
                });
                var SideBarInfo = () => {
                    return (
                        <section className={"performance-facts"}>
                        <div className={"performance-facts__header"}>
                            <p>By Starters/Relief</p>
                        </div>
                        {window.GA_reun_event("offRelief")}  
                        <table className={"performance-facts__table"}> 
                            <tbody>                                    
                        { 
                            Enumerable.from(viewByQuery).orderBy(c=> (c.position)).toArray().map((position) => 
                            {
                                return ( 
                                    <tr key={position.position + "sidebartotals"}>                                      
                                    <th colSpan={"2"}>
                                        <b>{position.position}</b>                                            
                                    </th>
                                    <td>{position.total} </td>
                                    </tr>
                                )
                            
                            })
                        }
                        </tbody>
                        </table>
                        </section>)};

                PageCom = () => { return (Enumerable.from(viewByQuery).where(c=> (c.position) == "Relief Pitcher").toArray().map((position) => (
                    <div key={position.position}>
                    <div className={"timeline-inning"}><span>{position.total} against Relief Pitchers!</span></div> 
                    { Enumerable.from(position.homers).orderByDescending(c=> new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                    
                     { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                        
                       return (  <div key={homer.playid}> 
                            <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                            <HomeRun homer={homer} gameinfo={gameinfo}/>
                         </div>
                        )}
                        )
                    }                                           
                    </div>
                    )) 
                )} ;
                break;           
            case 'pitchType':
                
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { pitchType: c.playjson[0].playinfo.pitchType}}).distinct(c=> c.pitchType).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.pitchType) == p.pitchType).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.pitchType) == p.pitchType).toArray();
                    });
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By pitch totals</p>
                            </div>
                            {window.GA_reun_event("pitchType")}
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                    
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> (c.pitchType)).toArray().map((pitchType) => 
                                {
                                    return ( 
                                        <tr key={pitchType.pitchType + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{pitchType.pitchType} </b>                                            
                                        </th>
                                        <td>{pitchType.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                          
                    PageCom = () => { return (Enumerable.from(viewByQuery).orderBy(c=> (c.pitchType)).toArray().map((pitchType) => (
                        <div key={pitchType.pitchType}>
                        <div className={"timeline-inning"}><span><b>{(pitchType.total)}</b> against {(pitchType.pitchType)}</span></div> 
                        { Enumerable.from(pitchType.homers).orderByDescending(c=>  new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'hitto':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { hrLocation: ExtractText(c.playjson[0])}}).distinct(c=> c.hrLocation).toArray();
                    console.log(viewByQuery);
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (ExtractText(c.playjson[0])) == p.hrLocation).count();
                        p.homers = Enumerable.from(homers).where(c=> (ExtractText(c.playjson[0])) == p.hrLocation).toArray();
                    });
                    console.log(viewByQuery);
                    var SideBarInfo = () => {
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>By Location</p>
                            </div>
                            {window.GA_reun_event("hrLocation")}
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                    
                            { 
                                Enumerable.from(viewByQuery).orderBy(c=> (c.hrLocation)).toArray().map((hrLocation) => 
                                {
                                    return ( 
                                        <tr key={hrLocation.hrLocation + "sidebartotals"}>                                      
                                        <th colSpan={"2"}>
                                            <b>{hrLocation.hrLocation} </b>                                            
                                        </th>
                                        <td>{hrLocation.total} </td>
                                        </tr>
                                    )
                                
                                })
                            }
                            </tbody>
                            </table>
                            </section>)};

                          
                    PageCom = () => { return (Enumerable.from(viewByQuery).orderBy(c=> (c.hrLocation)).toArray().map((hrLocation) => (
                        <div key={hrLocation.hrLocation}>
                        <div className={"timeline-inning"}><span><b>{(hrLocation.total)}</b> hit to {(hrLocation.hrLocation)}</span></div> 
                        { Enumerable.from(hrLocation.homers).orderByDescending(c=>  new Date(c.playjson[0].gameinfo.date)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            case 'pitchSpeed':
                viewByQuery= Enumerable.from(homers).select(function (c) {
                    return { pitchVelocity: c.playjson[0].playinfo.pitchVelocity,
                        pitchVelocityGrouping: get_pitchVelocity_group(parseInt(c.playjson[0].playinfo.pitchVelocity))}}).distinct(c=> c.pitchVelocity).toArray();
                    
                    viewByQuery.forEach(function (p){
                        p.total = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.pitchVelocity) == p.pitchVelocity).count();
                        p.homers = Enumerable.from(homers).where(c=> (c.playjson[0].playinfo.pitchVelocity) == p.pitchVelocity).toArray();
                    });
                    var SideBarInfo = () => {
                        
                            
                        return (
                            <section className={"performance-facts"}>
                            <div className={"performance-facts__header"}>
                                <p>Velocity totals</p>
                            </div>
                            {window.GA_reun_event("pitchSpeed")}
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                { 
                           
                           Enumerable.from(viewByQuery).groupBy(
                           c=> c.pitchVelocityGrouping,
                           element => element,
                           (key, items) => ({key, total: (items.toArray()).length}
                       
                       )).toArray().map((pitchVelocity) => 
                               {
                                   return ( 
                                       <tr key={pitchVelocity.key + "sidebartotals"}>                                      
                                       <th colSpan={"2"}>
                                           <b>{pitchVelocity.key} </b>                                            
                                       </th>
                                       <td>{pitchVelocity.total} </td>
                                       </tr>
                                   )
                               
                               })
                           }
                           </tbody>
                            </table>
                            <div className={"performance-facts__header"}>
                                <p>By pitch Velocity totals</p>
                            </div>
                            <table className={"performance-facts__table"}> 
                                <tbody>
                                
                            { 
                            
                            Enumerable.from(viewByQuery).orderBy(c=> (c.pitchVelocity)).toArray().map((pitchVelocity) => 
                            {
                                return ( 
                                    <tr key={pitchVelocity.pitchVelocity + "sidebartotals"}>                                      
                                    <th colSpan={"2"}>
                                        <b>{pitchVelocity.pitchVelocity} </b>                                            
                                    </th>
                                    <td>{pitchVelocity.total} </td>
                                    </tr>
                                )
                            
                            })
                        }
                            
                            
                            </tbody>
                            </table>
                            </section>
                            )};

                          
                    PageCom = () => { return (Enumerable.from(viewByQuery).orderBy(c=> (c.pitchVelocity)).toArray().map((pitchVelocity) => (
                        <div key={pitchVelocity.pitchVelocity}>
                        <div className={"timeline-inning"}><span><b>{(pitchVelocity.total)}</b> against {(pitchVelocity.pitchVelocity)}mph</span></div> 
                        { Enumerable.from(pitchVelocity.homers).orderByDescending(c=> parseInt(c.gameid)).toArray().map((homer) =>   
                        
                         { var gameinfo = Enumerable.from(games).where(c=> parseInt(c.gameid) == parseInt(homer.gameid)).firstOrDefault();
                            
                           return (  <div key={homer.playid}> 
                                <div className={"timeline-inning-date"}><span>{homer.playjson[0].gameinfo.date}</span></div>                                 
                                <HomeRun homer={homer} gameinfo={gameinfo}/>
                             </div>
                            )}
                            )
                        }                                           
                        </div>
                        )) 
                    )} ;
                    break;
            
            default:
              
          }
    }
    else {
        var groupedList = Enumerable.from(homers).select(function (c) {
        return {  id: c.playjson[0].batter.id, 
        batter: c.playjson[0].batter.name,
        photo: c.playjson[0].batter.photo,
            total: 0}}).distinct(c=> c.id).toArray();
        
        groupedList.forEach(function (p){
            p.total = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id).count();
            p.totalRHP = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id &&  c.playjson[0].pitcher.throws == "RHP").count();
            p.totalLHP = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id &&  c.playjson[0].pitcher.throws == "LHP").count();
            p.totalStarters = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id &&  c.playjson[0].pitcher.position == "Starting Pitcher").count();
            p.totalRelief = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id &&  c.playjson[0].pitcher.position == "Relief Pitcher").count();
            p.totalGoAhead = Enumerable.from(homers)
            .where(c=> c.playjson[0].batter.id ==p.id && 
            (
            (c.playjson[0].gameinfo.inning.type == "Bottom" 
            && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
            && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
            ) ||
            (c.playjson[0].gameinfo.inning.type == "Top" 
            && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
            && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
            )).count();
            p.gameWinning = Enumerable.from(homers)
            .where(c=> c.playjson[0].batter.id ==p.id && 
                (
                (c.playjson[0].gameinfo.inning.type == "Bottom" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                ) ||
                (c.playjson[0].gameinfo.inning.type == "Top" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                )).count();
            p.totalTieing = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id &&  parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away)).count();
            p.longestHR = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==p.id)
            .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
            .select(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")"))).firstOrDefault();
            
        });

        PageCom = () => { 
            
            return (               
                Enumerable.from(groupedList).orderByDescending(c=> parseInt(c.total)).toArray().map((player) => (
            <div key={player.id}>

            <div className={"timeline-date"}><span>{player.batter}</span></div> 
                <PlayerStats player={player}/>                                            
            </div>

            )) 
        )} ;
    }

    return (
        <div id={"root"}>
              <TopSocialLinks />
              <TopHeaderMenu />
              <div id={"main"}>			
                  <div id={"home"} className={"group"}>
                      <div id={"timeline"} className={"col span_2_of_3"}>   
                      <TeamStats />     
                      <PageCom />               
                      </div>
                      <div id={"sidebar"} className={"col span_1_of_3"}>
                          <div className={"widget widget_search"}> <SideBarInfo /></div>                       
                          <SideBarPlayers />
                      </div>
                  </div>                
              </div>
              <footer id={"footer"} className={"clearfix"}></footer>
      </div>
  );
}

export default Team

